<template>
  <report-layout title="Attachments" class="is-reversed">
    <p slot="desc">
      Occasionally we'll attach additional files to your monthly report,
      detailing things such as plugins updated, patches installed etc.
    </p>
    <p slot="desc">
      In {{ $report.timeframe }} we attached
      {{ $report.totalAttachments }} file(s).
    </p>

    <template slot="default">
      <input
        ref="fileUpload"
        type="file"
        name="name"
        class="is-hidden"
        multiple
        @change="onFileDialogUpload"
      />
      <div class="column is-12">
        <vessel>
          <p slot="topLeft">
            <strong>Attachments</strong>
          </p>
          <p slot="bottomLeft">{{ $report.totalAttachments }} file(s)</p>
          <button
            v-if="user.isAgent"
            slot="action"
            class="button is-outlined is-rounded is-dark"
            @click="$refs.fileUpload.click()"
          >
            Upload file
          </button>
          <template slot="body">
            <no-results
              v-if="!$report.totalAttachments"
              class="column is-12"
              icon="file-pdf"
              title="No attachments"
              message="This report has no attachments"
            />
            <div
              v-for="file in $report.attachments"
              v-else
              :key="file.path"
              class="report-attachment"
            >
              <attachment
                :loading="processing === file.path"
                :file-type="file.contentType"
                :file-name="file.name"
                :file-size="file.size"
                :file-ref="getFileRef(file)"
                :deleteable="user.isAgent"
                @onDelete="deleteAttachment(file)"
              />
            </div>
          </template>
        </vessel>
      </div>
    </template>
  </report-layout>
</template>

<script>
import { doc, updateDoc } from "@firebase/firestore";
import { ref as storageRef, deleteObject } from "firebase/storage";
export default {
  name: "ReportAttachmentsRibbon",
  inject: ["$reportProvider"],
  components: {
    "report-layout": () => import("./_layout"),
    attachment: () => import("@shared/tasks/_taskAttachment")
  },
  props: {
    siteId: {
      type: String,
      required: true
    },
    reportId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      processing: null
    };
  },
  computed: {
    $report() {
      return this.$reportProvider();
    },
    user() {
      return this.$store.getters["user/user"]();
    }
  },
  methods: {
    getFileRef(fileInfo) {
      if (!fileInfo) return null;
      return storageRef(this.$storage(fileInfo.bucket), fileInfo.path);
    },
    onFileDialogUpload(e) {
      this.processing = true;
      const promises = [];
      this.$_.each(e.target.files, file => {
        promises.push(this.uploadFile(file));
      });
      Promise.all(promises)
        .then(() => {
          this.$emit("uploaded");
          this.$toast.open({
            message: "File(s) uploaded"
          });
        })
        .finally(() => (this.processing = false));
      e.target.value = null;
    },
    uploadFile(file) {
      const id = `${new Date().getTime()}-${file.name}`;
      this.$emit("fileselected", {
        file,
        id,
        path: `${this.siteId}/${this.reportId}/${id}`
      });
      return this.$store
        .dispatch("sites/report/uploadFile", {
          siteId: this.siteId,
          reportId: this.reportId,
          id,
          file
        })
        .catch(() => {
          this.$toast.open({
            message: "Error while uploading file",
            type: "is-danger"
          });
        });
    },
    deleteAttachment(file) {
      const ref = this.getFileRef(file);
      if (!ref) return;
      this.$confirm.open({
        parent: this,
        props: {
          action: "Delete",
          message: `Are you sure you want to delete this file?`
        },
        events: {
          confirmed: () => {
            this.deleteConfirmed(file, ref);
          }
        }
      });
    },
    deleteConfirmed(file, ref) {
      const reportRef = doc(
        this.$firestore,
        `sites/${this.siteId}/reports/${this.reportId}`
      );
      const files = this.$_.filter(
        this.$report.attachments,
        i => i.path != file.path
      );
      Promise.all([
        deleteObject(ref),
        updateDoc(reportRef, {
          files
        })
      ])
        .then(() => {
          this.$toast.open({
            message: "File deleted"
          });
        })
        .catch(() => {
          this.$toast.open({
            message: "Error deleting file",
            type: "is-danger"
          });
        });
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
.report-attachment {
  .attachment {
    border: 1px solid $border;
    border-radius: 0.4em;
    padding: 0.75rem;
  }
}
</style>
